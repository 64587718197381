import React, { useState, useEffect, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useUserContext from "../../../hooks/useUserContext";
import PageableEntity from "../../PageableEntity";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import CreateEditUser from "../CreateEditUser";
import { Grid, Box } from "@material-ui/core";
import {
  onFilterChange,
  editUser,
  addUser,
  closeUserForm
} from "../../../reducers/users/userReducer";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useSelector } from "react-redux";

function getRowsByHeight(height) {
  return Math.round(height / 200);
}

const UserManagementContainer = () => {
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);

  const [users, setUsers] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const enqueueSnackbar = useEnqueueSnackbar();
  const { getUsers, deleteUsers, userReducer } = useUserContext();
  const [userData, userDispatch] = userReducer;
  const { hasPermissions } = useHasPermissions();
  const usersView = hasPermissions(["users.view"]);
  const usersDelete = hasPermissions(["users.delete"]);
  const usersAdd = hasPermissions(["users.add"]);
  const currentUserId = useSelector(state => state.user.UserID);

  const { textConfirmation } = useConfirmationDialog();

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    updateUserSet(userData.userPage, itemLimit, userData.userSearchTerm);
  }, [userData.userPage, itemLimit, userData.userSearchTerm, scopeAwareFacilityID]);

  const updateUserSet = useCallback(
    (page, limit = itemLimit, searchTerm = "") => {
      getUsers(scopeAwareFacilityID, limit, page ? (page - 1) * limit : 0, searchTerm)
        .then(res => {
          if (res.status === 200) {
            setUsers(res.data);
            formatUserData(res.data.collection);
          } else {
            enqueueSnackbar("We encountered a problem retrieving users", {
              variant: "error",
              tag: "ErrorRetrievingUsers"
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to retrieve users", {
            variant: "error",
            tag: "FailedToRetrieveUsers"
          });
        });
    },
    [scopeAwareFacilityID, itemLimit, userData.userPage, userData.userSearchTerm]
  );

  const formatUserData = rawUserData => {
    const formatted = rawUserData.map(user => {
      return {
        id: user.userId,
        dataId: user.email,
        displayContent: (
          <Grid data-id={user.email} container spacing={2}>
            <Grid item container direction="column">
              <Grid item>
                <Box fontWeight="bold" fontSize="large">
                  {`${user.firstName} ${user.lastName}`}
                </Box>
                <Box fontSize="medium">{user.email}</Box>
              </Grid>
            </Grid>
          </Grid>
        )
      };
    });

    setUsers(prev => ({ ...prev, display: formatted }));
  };

  const handleAddUser = () => {
    userDispatch({ type: addUser });
  };

  const handleUserSelected = userID => {
    userDispatch({ type: editUser, payload: { userID } });
  };

  const handleUserSearch = term => {
    userDispatch({
      type: onFilterChange,
      payload: { userSearchTerm: term, userPage: 1 }
    });
  };

  const handlePageChange = (e, page) => {
    userDispatch({ type: onFilterChange, payload: { userPage: page } });
  };

  const handleDeleteUsers = async userIDs => {
    let confirmed = false;

    if (userIDs.length == 0) {
      confirmed = false;
    } else {
      confirmed = await textConfirmation({
        title: "User Delete",
        message: `To delete the selected users, please enter the text EXACTLY as shown below.`,
        textConfirmation: "DELETE USERS"
      });
      refreshList();
    }

    if (confirmed) {
      deleteUsers(scopeAwareFacilityID, userIDs)
        .then(res => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully deleted users", {
              variant: "success"
            });
            refreshList();
          } else {
            enqueueSnackbar("We encountered a problem deleting users", {
              variant: "error",
              tag: "FailedDeletingUsers"
            });
          }
        })
        .catch(err => {
          if (
            err.response.status === 400 &&
            typeof err.response.data === "string"
          )
            enqueueSnackbar(err.response.data, { variant: "warning" });
          else
            enqueueSnackbar("Failed to delete users", {
              variant: "error",
              tag: "FailedToDeleteUsers"
            });
        });
    }
  };

  const refreshList = () => {
    updateUserSet(userData.userPage, itemLimit, userData.userSearchTerm);
  };

  const handleCancel = () => {
    userDispatch({ type: closeUserForm });
  };

  const handleDelete = () => {
    userDispatch({ type: closeUserForm });
  };

  const handleUpdate = () => {
    refreshList();
    userDispatch({ type: closeUserForm });
  };

  if (!usersView) {
    return "You don't have access to view this page.";
  }

  return (
    <Switch>
      <Route exact path="/users">
        {userData.userFormOpen === false ? (
          <PageableEntity
            dataID="users-pageable"
            title="User Management"
            searchBarLabel="Users"
            searchBarPlaceHolder="Search by Name or email address"
            addBtnLabel="Add User"
            addBtnClicked={handleAddUser}
            items={users.display}
            onItemClicked={handleUserSelected}
            onSearchChange={handleUserSearch}
            totalCount={Math.ceil(users.totalCount / itemLimit)}
            onPageChange={handlePageChange}
            searchDebounce={100}
            deleteBtnClicked={handleDeleteUsers}
            defaultSearch={userData.userSearchTerm}
            hideDeleteBtn={!usersDelete}
            currentPage={userData.userPage ?? 1}
            hideAddBtn={!usersAdd}
            disableCheckBoxForID={currentUserId}
          />
        ) : (
          <CreateEditUser
            onUpdate={handleUpdate}
            onDeleteClick={handleDelete}
            userID={userData.userID}
            onCancelClick={handleCancel}
          />
        )}
      </Route>
    </Switch>
  );
};

export default UserManagementContainer;
