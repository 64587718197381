import { makeStyles } from "@material-ui/core/styles";

/**
 * This component is outside of our theme provider, so unfortunately we have to define the colors ourselves
 * As of writing this comment, these are the colors defined in our theme palette
 */
const useStyles = makeStyles(() => ({
  primaryBtn: {
    backgroundColor: "#006ba6",
    color: "#fff",
  },
  secondaryBtn: {
    backgroundColor: "#e0e0e0",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default useStyles;
