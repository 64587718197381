import { makeStyles } from "@material-ui/core/styles";

/**
 * This component is outside of our theme provider, so unfortunately we have to define the colors ourselves
 * As of writing this comment, these are the colors defined in our theme palette
 */
const useStyles = makeStyles(() => ({
  maxZIndex: {
    zIndex: 1000001
  }
}));

export default useStyles;