import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import { useStyles } from "./styles";
import * as Yup from "yup";
import Title from "../../../Title";
import { isUndefined } from "lodash";
import { isGuid } from "../../../../App";
import useUserContext from "../../../../hooks/useUserContext";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import UserPanel from "../../../Panels/Users";
import clsx from "clsx";
import PermissionPanel from "../../../Panels/Permissions";
import { useConfirmationDialog } from "../../../../hooks/useConfirmationDialog";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

const UserGroupForm = ({
  data,
  onDeleteComplete,
  onSubmitComplete,
  onCancel
}) => {
  const classes = useStyles();
  const isEditGroup = !isUndefined(data.groupID) && isGuid(data.groupID);
  const {
    updateUserGroup,
    deleteUserGroups,
    createUserGroup
  } = useUserContext();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const { textConfirmation } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const userGroupEdit = hasPermissions(["usergroups.edit"]);
  const userGroupAdd = hasPermissions(["usergroups.add"]);
  const userGroupDelete = hasPermissions(["usergroups.delete"]);
  const usersView = hasPermissions(["users.view"]);

  const handleDelete = async () => {
    let confirmed = false;

    if (data.userIDs.length == 0) {
      confirmed = true;
    } else {
      confirmed = await textConfirmation({
        title: "User Group Delete",
        message: `Users are assigned to this group.  To delete this group, please enter the user group name EXACTLY as shown below.`,
        textConfirmation: data.name
      });
    }

    if (confirmed) {
      deleteUserGroups([data.groupID])
        .then(res => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully deleted group", {
              variant: "success"
            });
            onDeleteComplete();
          } else if (res.status === 400) {
            enqueueSnackbar(`Unable to delete group: ${res.data}`, {
              variant: "error",
              tag: "UnableToDeleteGroup"
            });
          } else {
            enqueueSnackbar(
              "We encountered a problem while deleting the group",
              {
                variant: "error",
                tag: "ErrorDeletingGroup"
              }
            );
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to delete group", {
            variant: "error",
            tag: "FailedToDeleteGroup"
          });
        });
    }
  };

  const createOrUpdateGroup = async values => {
    const { name, description, userIDs, permissions } = values;
    const group = { name, description, userIDs, permissions };
    group.entityID = scopeAwareFacilityID;
    if (isEditGroup) {
      group.groupID = data.groupID;
      updateUserGroup(group)
        .then(res => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully updated group", {
              variant: "success"
            });
            onSubmitComplete();
          } else {
            enqueueSnackbar("We encounted a problem updating the group", {
              variant: "error",
              tag: "ErrorUpdatingGroup"
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to update group", {
            variant: "error",
            tag: "FailedToUpdateGroup"
          });
        });
    } else {
      createUserGroup(group)
        .then(res => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully created group", {
              variant: "success"
            });
            onSubmitComplete();
          } else {
            enqueueSnackbar("We encounted a problem creating the group", {
              variant: "error",
              tag: "ErrorCreatingGroup"
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to create group", {
            variant: "error",
            tag: "FailedToCreateGroup"
          });
        });
    }
  };

  const shouldBeDisabled = (overrideDefaultGroup = false) => {
    if (overrideDefaultGroup === false && data.defaultGroup === true)
      return true;
    if (isEditGroup === true) {
      return !userGroupEdit;
    } else {
      return !userGroupAdd;
    }
  };

  const GroupSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string(),
    userIDs: Yup.array(Yup.string())
  });

  return (
    <Box className={clsx(classes.root)}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Title className={clsx("user-group-title")}>
            {isEditGroup === true ? "Edit " : "Create "} User Group
          </Title>
        </Grid>
        {isEditGroup === true && userGroupDelete && (
          <Grid item xs={3} className={clsx(classes.deleteBtnGrid)}>
            {!data.defaultGroup && (
              <Button
                className={clsx("delete-btn")}
                startIcon={<DeleteIcon />}
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Formik
        onSubmit={async values => {
          await createOrUpdateGroup(values);
        }}
        enableReinitialize
        validateOnChange={false}
        initialValues={data}
        validationSchema={GroupSchema}
      >
        {({ submitForm, setFieldValue }) => (
          <Form className={clsx("user-group-form")}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  className={clsx("group-name", classes.input)}
                  variant="outlined"
                  name="name"
                  label="Group Name"
                  disabled={shouldBeDisabled()}
                  InputProps={{
                    readOnly: Boolean(shouldBeDisabled()),
                    "aria-readonly": Boolean(shouldBeDisabled()),
                    disabled: Boolean(shouldBeDisabled())
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  className={clsx("group-description", classes.input)}
                  variant="outlined"
                  name="description"
                  label="Description"
                  disabled={shouldBeDisabled()}
                  InputProps={{
                    readOnly: Boolean(shouldBeDisabled()),
                    "aria-readonly": Boolean(shouldBeDisabled()),
                    disabled: Boolean(shouldBeDisabled())
                  }}
                />
              </Grid>
              {usersView === true && (
                <Grid item xs={12}>
                  <UserPanel
                    selected={data.userIDs}
                    onChange={userIDs => {
                      setFieldValue("userIDs", userIDs);
                    }}
                    disabled={shouldBeDisabled(true)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <PermissionPanel
                  selected={data.permissions}
                  onChange={permissions => {
                    setFieldValue("permissions", permissions);
                  }}
                  disabled={shouldBeDisabled()}
                />
              </Grid>
              <Grid
                className={clsx(classes.btnContainer)}
                container
                spacing={2}
              >
                <Grid item>
                  {!shouldBeDisabled(true) && (
                    <Button
                      className={clsx("save-btn")}
                      startIcon={<SaveIcon />}
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      {isEditGroup === true ? "Update" : "Create"}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    className={clsx("cancel-btn")}
                    startIcon={<CancelIcon />}
                    variant="contained"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

UserGroupForm.defaultProps = {
  data: {},
  onSubmitComplete: () => {},
  onDeleteComplete: () => {},
  onCancel: () => {},
  onMessage: () => {}
};

UserGroupForm.propTypes = {
  data: PropTypes.shape({
    groupID: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    entityID: PropTypes.string
  }),
  onSubmitComplete: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onMessage: PropTypes.func
};

export default UserGroupForm;
