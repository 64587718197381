import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.warning.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    },
  },
  contentContainer: {
    whiteSpace: "pre-wrap",
  },
}));