import React from "react";
import PropTypes from "prop-types";
import MuiTreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useStyles } from "./styles";
import StyledTreeItem from "./StyledTreeItem";
import { Link, useHistory } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import useHasPermissions from "../../hooks/useHasPermissions";

const TreeView = ({ ["data-id"]: dataID, treeItems, onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  // const { hasPermissions } = useAuthContext();
  const { hasPermissions } = useHasPermissions();

  const handleRootClick = link => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <MuiTreeView
      data-id={dataID}
      className={classes.root}
      defaultExpanded={["3"]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {treeItems.map((item, index) => {
        if (item.permissions && !hasPermissions(item.permissions)) return <></>;
        else
          return (
            <StyledTreeItem
              key={index}
              nodeId={index.toString()}
              data-id={`${item.name}-tree-item`}
              labelText={item.name}
              labelIcon={item.icon}
              onClick={() => {
                handleRootClick(item.link);
                onClick(item);
              }}
            >
              {item.subItems?.map((subItem, index) => {
                return (
                  <Link key={index} className={classes.link} to={subItem.link}>
                    <StyledTreeItem
                      data-id={`${subItem.name}-tree-item`}
                      labelText={subItem.name}
                      labelIcon={subItem.icon}
                      onClick={() => onClick(item)}
                    />
                  </Link>
                );
              })}
            </StyledTreeItem>
          );
      })}
    </MuiTreeView>
  );
};

TreeView.defaultProps = {
  treeItems: [],
  onClick: () => {}
};

TreeView.propTypes = {
  treeItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.object,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          icon: PropTypes.object
        })
      )
    })
  )
};

export default TreeView;
