import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: "80%",
  },
  input: {
    width: "100%",
  },
  gridContainer: {},
  deleteBtnGrid: {
    textAlign: "right",
  },
  selectError: {
    color: "#f44336",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
  btnContainer: {
    marginLeft: "0px",
  },
}));
