import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import PageableEntity from "../../../PageableEntity";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useUserContext from "../../../../hooks/useUserContext";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CreateEditUserGroup from "../CreateEditUserGroup";
import { Grid, Box } from "@material-ui/core";
import {
  onFilterChange,
  closeGroupForm,
  addGroup,
  editGroup
} from "../../../../reducers/users/userReducer";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

function getRowsByHeight(height) {
  return Math.round(height / 200);
}

const UserGroupManagementContainer = () => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const history = useHistory();
  const [groups, setGroups] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const { getUserGroups, deleteUserGroups, userReducer } = useUserContext();
  const [groupData, groupDispatch] = userReducer;
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const { hasPermissions } = useHasPermissions();
  const userGroupsView = hasPermissions(["usergroups.view"]);
  const userGroupsAdd = hasPermissions(["usergroups.add"]);

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    updateUserGroupSet(
      groupData.groupPage,
      itemLimit,
      groupData.groupSearchTerm
    );
  }, [scopeAwareFacilityID, itemLimit, groupData.groupPage, groupData.groupSearchTerm]);

  const updateUserGroupSet = (page, limit = itemLimit, searchTerm = "") => {
    getUserGroups(scopeAwareFacilityID, limit, page ? (page - 1) * limit : 0, searchTerm)
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data);
          formatGroupData(res.data.collection);
        } else {
          enqueueSnackbar("We encountered a problem retrieving user groups", {
            variant: "error",
            tag: "ErrorRetrievingUserGroups"
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve user groups", {
          variant: "error",
          tag: "FailedToRetrieveUserGroups"
        });
      });
  };

  const formatGroupData = rawGroups => {
    const formatted = rawGroups.map(group => {
      return {
        id: group.groupID,
        name: group.name,
        displayContent: (
          <Grid container spacing={2}>
            <Grid item container direction="column">
              <Grid item>
                <Box fontWeight="bold" fontSize="large">
                  {group.name}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      };
    });

    setGroups(prev => ({ ...prev, display: formatted }));
  };

  const handleAddUserGroup = () => {
    groupDispatch({ type: addGroup });
  };

  const handleGroupSelected = groupID => {
    groupDispatch({ type: editGroup, payload: { groupID } });
  };

  const handleGroupSearch = searchTerm => {
    groupDispatch({
      type: onFilterChange,
      payload: { groupSearchTerm: searchTerm, groupPage: 1 }
    });
  };

  const handleDeleteGroups = groupIDs => {
    if (groupIDs?.length === 0) {
      return;
    }

    deleteUserGroups(groupIDs)
      .then(res => {
        if (res.status === 200) {
          enqueueSnackbar("Successfully deleted user groups", {
            variant: "success"
          });
          refreshList();
        } else {
          enqueueSnackbar("We encountered a problem deleting user groups", {
            variant: "error",
            tag: "ErrorDeletingUserGroups"
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Error deleting user groups", {
          variant: "error",
          tag: "FailedDeletingUserGroups"
        });
      });
  };

  const handlePageChange = (e, page) => {
    groupDispatch({ type: onFilterChange, payload: { groupPage: page } });
  };

  const refreshList = () => {
    updateUserGroupSet(
      groupData.groupPage,
      itemLimit,
      groupData.groupSearchTerm
    );
  };

  const handleCancel = () => {
    groupDispatch({ type: closeGroupForm });
  };

  const handleSubmit = () => {
    refreshList();
    groupDispatch({ type: closeGroupForm });
  };

  const handleDelete = () => {
    refreshList();
    groupDispatch({ type: closeGroupForm });
  };

  if (!userGroupsView) {
    return "You don't have access to view this page.";
  }

  return (
    <Switch>
      <Route exact path="/users/groups">
        {groupData.groupFormOpen === false ? (
          <PageableEntity
            dataID="groups-pageable"
            title="User Group Management"
            searchBarLabel="User Groups"
            addBtnLabel="Add Group"
            hideDeleteBtn
            addBtnClicked={handleAddUserGroup}
            items={groups.display}
            onItemClicked={handleGroupSelected}
            onSearchChange={handleGroupSearch}
            totalCount={Math.ceil(groups.totalCount / itemLimit)}
            onPageChange={handlePageChange}
            searchDebounce={100}
            deleteBtnClicked={handleDeleteGroups}
            currentPage={groupData.groupPage ?? 1}
            defaultSearch={groupData.groupSearchTerm}
            hideAddBtn={!userGroupsAdd}
          />
        ) : (
          <CreateEditUserGroup
            onSubmitComplete={handleSubmit}
            onDeleteComplete={handleDelete}
            groupID={groupData.groupID}
            onCancel={handleCancel}
          />
        )}
      </Route>
    </Switch>
  );
};

export default UserGroupManagementContainer;
