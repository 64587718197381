import React from "react";
import {Grid, Hidden} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import {useStyles} from "./styles";
import {useFlags} from "launchdarkly-react-client-sdk";

export const WarningBanner = () => {
  const classes = useStyles();
  const {portalBanner} = useFlags();

  return (
    <>
      {portalBanner?.enabled &&
        <>
          <Hidden smDown>
            <Grid container className={classes.root}>
              <Grid item xs={2} md={2}>
                <WarningIcon></WarningIcon>
              </Grid>
              <Grid className={classes.contentContainer} item xs={10}>
                <span dangerouslySetInnerHTML={{__html: portalBanner?.message}}/>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container className={classes.root}>
              <Grid className={classes.contentContainer} item xs={"auto"}>
                <span dangerouslySetInnerHTML={{__html: portalBanner?.message}}/>
              </Grid>
            </Grid>
          </Hidden>
        </>
      }
    </>
  );
};