import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: "fixed",
  },
  cells: {
    overflow: "hidden",
  },
  textContainer: {
    display: "block",
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  skeletonWrapper:{
		textAlign:"center",
		"&.skeleton":{
			color:theme.palette.amano.base.primary.main,
			backgroundColor:theme.palette.amano.base.primary.main,
		}
	},
  cardHeader: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.header,
    color: theme.palette.text.secondary,
    ...theme.typography.button,
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
    },
  },
}));
