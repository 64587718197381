import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import UserGroupForm from "../Form";
import useUserContext from "../../../../hooks/useUserContext";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { isUndefined } from "lodash";

const CreateEditUserGroup = ({
  groupID,
  onCancel,
  onSubmitComplete,
  onDeleteComplete
}) => {
  const classes = useStyles;
  const enqueueSnackbar = useEnqueueSnackbar();
  const [group, setGroup] = useState({
    groupID,
    name: "",
    description: "",
    userIDs: [],
    permissions: []
  });
  const { getUserGroup } = useUserContext();

  useEffect(() => {
    if (!isUndefined(groupID)) {
      getGroupInfo();
    }
  }, []);

  const getGroupInfo = () => {
    getUserGroup(groupID)
      .then(res => {
        if (res.status === 200) {
          setGroup(res.data);
        } else {
          enqueueSnackbar(
            "We encountred a problem retrieving the group information",
            { variant: "error", tag: "ErrorRetrievingGroupInformation" }
          );
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve group info", {
          variant: "error",
          tag: "FailedToRetrieveGroupInfo"
        });
      });
  };

  return (
    <UserGroupForm
      data={group}
      onCancel={onCancel}
      onSubmitComplete={onSubmitComplete}
      onDeleteComplete={onDeleteComplete}
    />
  );
};

CreateEditUserGroup.defaultProps = {
  onSubmitComplete: () => {},
  onCancel: () => {},
  onDeleteComplete: () => {}
};

CreateEditUserGroup.propTypes = {
  onSubmitComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func
};

export default CreateEditUserGroup;
