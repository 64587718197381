import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import Box from "@material-ui/core/Box";
import Title from "../../Title";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CancelIcon from "@material-ui/icons/Cancel";
import useUserContext from "../../../hooks/useUserContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import GroupsPanel from "../../Panels/Groups";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { isUndefined } from "lodash";
import { useSelector } from "react-redux";

const CreateEditUser = ({ onUpdate, userID, onCancelClick, onDeleteClick }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const currentUserId = useSelector(state => state.user.UserID);

  const DEFAULT_USER = {
    userId: userID,
    firstName: "",
    lastName: "",
    email: "",
    entities: []
  };

  const DEFAULT_USER_AUTH = {
    inheritedPermissions: [],
    entityGroups: []
  };

  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const isNewUser = isUndefined(userID);
  const {
    getUser,
    getUserByEmail,
    createUser,
    updateUser,
    deleteUser,
    resendUserInvitation,
    assignUserGroups
  } = useUserContext();
  const [isLoading, setIsLoading] = useState(!isNewUser);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [user, setUser] = useState(DEFAULT_USER);
  const [userAuth, setUserAuth] = useState(DEFAULT_USER_AUTH);
  const [groups, setGroups] = useState([]);
  const { hasPermissions } = useHasPermissions();
  const usersAdd = hasPermissions(["users.add"]);
  const usersEdit = hasPermissions(["users.edit"]);
  const usersDelete = hasPermissions(["users.delete"]);

  const { textConfirmation, showDialog } = useConfirmationDialog();

  const populateUser = useCallback(
    userId => {
      retrieveUser(userId, [], true);
    },
    [scopeAwareFacilityID, getUser, history]
  );

  async function retrieveUser(userId, newGroups, userSet) {
    try {
      var result = await getUser(userId);
      var userData = {};
      if (result?.status === 200) {
        let user = result.data;
        const entities = user.entities;
        if (entities && entities.length > 0) {
          const entity = user.entities.find(x => x.scope === scopeAwareFacilityID);
          const entityGroups = entity?.groups ?? [];
          let parentGroups = user.entities.map(x =>
            x.entityLevel < entity?.entityLevel || x.scope === scopeAwareFacilityID
              ? x.groups
              : []
          );
          parentGroups = parentGroups.reduce(
            (acc, val) => [...acc, ...val],
            []
          );
          setGroups(
            entityGroups
              .flatMap(x => x.groupID)
              .concat(newGroups.flatMap(x => x.groupID))
          );
          setUserAuth({
            inheritedPermissions: parentGroups.flatMap(x => x.permissions),
            entityGroups: entityGroups
              .flatMap(x => x.groupID)
              .concat(newGroups.flatMap(x => x.groupID))
          });
        }
        if (userSet) {
          setUser({
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            entities: user.entities,
            userType: user.userType
          });
        }
        userData = {
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          entities: user.entities,
          userType: user.userType
        };
      }
      setIsLoading(false);
      return userData;
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar("Unable to get user", {
        variant: "error",
        tag: "FailedToGetUser"
      });
      history.goBack();
    }
  }

  async function findUserByEmail(email) {
    var userId = null;
    try {
      var response = await getUserByEmail(email);
      if (response.status === 200) {
        userId = response?.data;
      }
    } catch (ex) {
      if (
        !(ex.response?.status === 404 && ex.response?.data === "User Not Found")
      ) {
        enqueueSnackbar("Unable to search existing user", {
          variant: "error",
          tag: "ErroSearchingExistingUser"
        });
      }
    }
    return userId;
  }

  async function checkUser(values) {
    var newUser = isNewUser;
    if (isNewUser) {
      var userID = await findUserByEmail(values.email);
      if (userID) {
        values = await retrieveUser(userID, userAuth.entityGroups, false);
        newUser = false;
      }
    }
    await createOrUpdateUser(values, newUser);
  }

  async function createOrUpdateUser(values, newUser) {
    if (!values.entities) values.entities = [];
    const currentEntity = values.entities.findIndex(x => x.scope === scopeAwareFacilityID);

    const currentGroups = userAuth.entityGroups.map(x => {
      return { groupID: x };
    });

    if (currentEntity < 0)
      values.entities.push({
        scope: scopeAwareFacilityID,
        groups: currentGroups
      });
    else {
      values.entities[currentEntity].groups = currentGroups;
    }

    for (var entity of values.entities) {
      delete entity.permissions; // we only care about group inheritence at this point.
    }

    // Only include the scope being updated
    values.entities = values.entities.filter(x => x.scope == scopeAwareFacilityID);

    if (newUser) {
      return await createUser(values)
        .then(result => {
          if (result?.status === 200) {
            enqueueSnackbar("User Created!", { variant: "success" });
          }
          onUpdate();
          history.push(`/users`);
        })
        .catch(() => {
          enqueueSnackbar("Unable to create user", {
            variant: "error",
            tag: "UnableToCreateUser"
          });
        });
    } else {
        if(values.userType === 1 || values.userType === "ValidationAccount"){
          enqueueSnackbar(
            "Existing validation account user.  Please delete validation account user before adding as a system user.",
            { variant: "error" });
        } else {
          return await assignUserGroups(values)
            .then(result => {
              if (result?.status === 200) {
                enqueueSnackbar("User updated!", { variant: "success" });
              }
              onUpdate();
              history.push(`/users`);
            })
            .catch(() => {
              enqueueSnackbar("Unable to update user", {
                variant: "error",
                tag: "FailedToUpdateUser"
              });
            });
        }
    }
  }

  const handleDeleteUser = async () => {
    let confirmed = false;

    if (user.userId.length == 0) {
      confirmed = false;
    } else {
      confirmed = await textConfirmation({
        title: "User Delete",
        message: `To delete this user, please enter the user email EXACTLY as shown below.`,
        textConfirmation: user.email
      });
    }

    if (confirmed) {
      setIsDeleting(true);
      deleteUser(scopeAwareFacilityID, userID)
        .then(result => {
          setIsDeleting(false);
          if (result?.status === 200) {
            enqueueSnackbar("User Deleted!", { variant: "success" });
          }
          onUpdate();
          onDeleteClick();
          history.push(`/users`);
        })
        .catch(() => {
          setIsDeleting(false);
          enqueueSnackbar("Unable to delete user", {
            variant: "error",
            tag: "UmableToDeleteUser"
          });
        });
    }
  };

  const handleResendInvitation = () => {
    setIsResetting(true);
    resendUserInvitation(user)
      .then(result => {
        if (result?.status === 200) {
          setIsResetting(false);
          enqueueSnackbar("Email has been sent", { variant: "success" });
        }
      })
      .catch(error => {
        setIsResetting(false);
        if (
          error?.response?.data?.includes("status is not FORCE_CHANGE_PASSWORD")
        )
          enqueueSnackbar("User has already logged in and set their password");
        else
          enqueueSnackbar(
            `Failed to resend user invitation: ${error.response.data}`,
            { variant: "error", tag: "FailedToResendInvitation" }
          );
      });
  };

  useEffect(() => {
    if (!isNewUser) {
      populateUser(userID);
    }
  }, [isNewUser, populateUser, userID]);

  const shouldBeDisabled = () => {
    if (isNewUser === true) {
      return !usersAdd;
    } else {
      return !usersEdit;
    }
  };

  const shouldNotSubmit = () => {
    return (
      JSON.stringify(groups) === JSON.stringify(userAuth.entityGroups) ||
      userAuth.entityGroups.length == 0
    );
  };

  const UserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
  });

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Title>
            {isNewUser ? "Create User" : "Edit User"}
            {isLoading && <CircularProgress size={24} />}
          </Title>
        </Grid>
        {!isNewUser && usersDelete && (
          <Grid item xs={2} className={classes.deleteBtnGrid}>
            <Button
              data-id="delete-button"
              startIcon={
                isDeleting ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <DeleteIcon />
                )
              }
              variant="contained"
              color="secondary"
              disabled={isDeleting || isLoading || userID == currentUserId}
              onClick={() => handleDeleteUser()}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      <Formik
        enableReinitialize
        initialValues={user}
        onSubmit={async values => {
          await checkUser(values);
        }}
        validationSchema={UserSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            {!isNewUser && (
              <>
                <Field
                  data-id="firstname-field"
                  component={TextField}
                  className={classes.input}
                  variant="outlined"
                  name="firstName"
                  label="First Name"
                  disabled={true}
                  InputProps={{
                    readOnly: true,
                    "aria-readonly": true,
                    disabled: true
                  }}
                />
                <Field
                  data-id="lastname-field"
                  component={TextField}
                  className={classes.input}
                  variant="outlined"
                  name="lastName"
                  label="Last Name"
                  disabled={true}
                  InputProps={{
                    readOnly: true,
                    "aria-readonly": true,
                    disabled: true
                  }}
                />
              </>
            )}
            <Field
              data-id="email-field"
              component={TextField}
              className={classes.input}
              variant="outlined"
              name="email"
              type="email"
              label="Email"
              disabled={shouldBeDisabled() || !isNewUser}
              InputProps={{
                readOnly: Boolean(shouldBeDisabled()) || !isNewUser,
                "aria-readonly": Boolean(shouldBeDisabled()) || !isNewUser,
                disabled: Boolean(shouldBeDisabled() || !isNewUser)
              }}
            />
            <GroupsPanel
              className={classes.input}
              selected={[...userAuth.entityGroups]}
              onChange={groups => {
                setUserAuth(prev => ({ ...prev, entityGroups: groups }));
              }}
              groupType="user"
              disabled={shouldBeDisabled()}
            />
            <Grid container spacing={2}>
              <Grid item>
                {!shouldBeDisabled() && (
                  <Button
                    data-id="save-button"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || isLoading || shouldNotSubmit()}
                    onClick={submitForm}
                  >
                    {isNewUser ? "Create" : "Update"}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  data-id="cancel-button"
                  startIcon={<CancelIcon />}
                  variant="contained"
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              {!isNewUser && !shouldBeDisabled() && (
                <Grid item xs={4}>
                  <Button
                    data-id="reset-password-button"
                    startIcon={
                      isResetting ? (
                        <CircularProgress size={24} color="secondary" />
                      ) : (
                        <RotateLeftIcon />
                      )
                    }
                    variant="contained"
                    disabled={isResetting || isLoading}
                    onClick={handleResendInvitation}
                  >
                    Resend Invitation
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

CreateEditUser.defaultProps = {
  onUpdate: () => {},
  onCancelClick: () => {},
  onDeleteClick: () => {}
};

CreateEditUser.propTypes = {
  onUpdate: PropTypes.func,
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

export default CreateEditUser;
