import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { Prompt } from "react-router-dom";
import useStyles from "./styles";

export const NavConfirmation = ({ when, title, message, buttons }) => {
  useEffect(() => {
    window.addEventListener("beforeunload", alertOverride);
    return () => {
      window.removeEventListener("beforeunload", alertOverride);
    };
  }, []);

  const alertOverride = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <Prompt when={when} message={JSON.stringify({ title, message, buttons })} />
  );
};

NavConfirmation.defaultProps = {
  when: true,
};

NavConfirmation.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number,
      text: PropTypes.string,
      state: PropTypes.any,
      color: PropTypes.oneOf(["primary", "secondary"]),
    })
  ),
};

/**
 * Only to be used with the BrowserRouter
 */
const UserConfirmation = (message, callback) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const close = (state) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(state);
  };

  const options = JSON.parse(message) ?? {};

  ReactDOM.render(
    <UserConfirmationContent
      message={options.message}
      title={options.title}
      buttons={options.buttons}
      callback={close}
    />,
    container
  );
};

const UserConfirmationContent = ({ message, title, buttons, callback }) => {
  const classes = useStyles();
  return (
    <Dialog open={true} data-testid="user-confirmation-dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons
          ?.sort((x) => x.order)
          .map((button, index) => (
            <Button
              className={
                button.color === "primary"
                  ? classes.primaryBtn
                  : classes.secondaryBtn
              }
              variant="contained"
              key={index}
              data-id={button.attribute}
              onClick={() => callback(button.state)}
            >
              {button.text}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default UserConfirmation;
