export const deviceOptionsList = ["setting.devicemode", "setting.graceperiod","setting.devicedisabled", "setting.laneclosedsign", "setting.backouttimeout", "setting.toidletimeout", "setting.centralpaytrxtimeout"];
export const printerOptionsList = ["setting.autoprintreceipt", "setting.receipt.header", "setting.receipt.footer"];
export const lostTicketOptionsList = ["setting.lostticketbuttonisenabled"];
export const devicemodeslist = ["Entry", "Exit", "Kiosk"];
export const emailOptionsList = ["setting.emailfrom", "setting.ccreplyto", "setting.emailreply", "setting.emailtemplate"]
export const ticketOptionsList = ["setting.issueticketwhilefull", "setting.ticket.header", "setting.ticket.footer"]
export const thirdPartyOptionsList = ["setting.thirdpartyaccessgroup", "setting.thirdpartyrate"];
export const settingsTabs = [
  {name:"device", friendlyname:"Device", listName:"deviceOptionsList"},
  {name:"printer", friendlyname:"Printer", listName:"printerOptionsList"},
  {name:"lostticket", friendlyname:"Lost Ticket", listName:"lostTicketOptionsList"}, 
  {name:"email", friendlyname:"Email", listName:"emailOptionsList"},
  {name:"ticket", friendlyname:"Ticket", listName:"ticketOptionsList"}
];
export const GetTabInfo = (tab) => {
  var found = settingsTabs.find(element => element.name === tab);
  if (found) {
    return found;
  } else {
    return {name:tab, friendlyname:tab, listName:""};
  }
};