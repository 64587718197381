import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

export const verifyCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required("Required"),
  newPassword: Yup.string()
    .required("Required")
});

const VerifyCodeForm = ({ onCancel, isResending, resendCode, email, onSubmit, cognitoErrors }) => {
  const classes = useStyles();
  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(verifyCodeSchema)
  });

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(async (values) => await onSubmit(values))}
      noValidate
    >
      {email && !cognitoErrors?.resend && (
        <div className={classes.email}>
          {`Verification code was sent to ${email}. `}
          {isResending ? (
            <CircularProgress size={24} />
          ) : (
            <Link onClick={() => resendCode(email)} className={classes.resend} variant="body2">
              Resend
            </Link>
          )}
        </div>
      )}

      {cognitoErrors?.resend && cognitoErrors.resend.message && (
        <div className={classes.error}>
          {cognitoErrors.resend.message}
        </div>
      )}

      <Controller
        name="code"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
          id="code"
          label="Verification Code"
          autoFocus
          error={!!errors.code || !!cognitoErrors?.code}
          helperText={(errors.code && errors.code.message) || (cognitoErrors?.code && cognitoErrors.code.message)}
        />}
      />


      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="New Password"
          type="password"
          id="newPassword"
          autoComplete="off"
          error={!!errors.newPassword || !!cognitoErrors?.newPassword}
          helperText={(errors.newPassword && errors.newPassword.message) || (cognitoErrors?.newPassword && cognitoErrors.newPassword.message)}
        />}
      />

      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Reset Password
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      <div className={classes.submitWrapper}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default VerifyCodeForm;
