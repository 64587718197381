import React, { useState, useImperativeHandle, forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";

const ValidationMemo = forwardRef(({ onInputChange, maxLength }, ref) => {
  const [remainingChars, setRemainingChars] = useState(maxLength);
  const [MemoData, setmemoData] = useState("");

  useImperativeHandle(ref, () => ({
    resetValidationMemo: () => {
      setmemoData("");
      onInputChange("");
      setRemainingChars(maxLength);
    }
  }), [maxLength]);

  const updateRemainingChars = (e) => {
    let inputText = e.target.value;
    const charsLeft = maxLength - inputText.length;
    
    if (charsLeft < 0) {
      inputText = inputText.slice(0, maxLength);
    }else{
      setRemainingChars(charsLeft);
    }
    setmemoData(inputText);
    onInputChange(inputText);
  };
  
  return (
    <TextField
      className={clsx([`text-validation-memo`])}
      variant="outlined"
      fullWidth
      label="Memo"
      name="onlineValidationMemo"
      type="text"
      value= {MemoData}
      onChange={(e) => {
        updateRemainingChars(e);
      }}
      helperText={`Max ${maxLength} Characters. ${remainingChars} left`}
      FormHelperTextProps={{
        'data-testid': 'onlinevalidationMemo-helpertext',
      }}
      InputProps={{
        'data-testid': 'onlinevalidationMemoTextField',
      }}
    />
  );
});

export default ValidationMemo;

ValidationMemo.displayName = 'ValidationMemo';
