import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  deleteBtnGrid: {
    textAlign: "right",
  },
  input: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: "40%",
  },
  permissionContainer: {
    width: "81%",
  },
  panelSummary: {
    height: "55px",
  },
  permission: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
    margin: 0,
    "& span": {
      fontSize: 12,
    },
  },
}));
