import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    email: {
        marginLeft: 10,
        marginBottom: 10,
        marginRight: 10,
        color: theme.palette.primary.dark,
    },
    profile: {
        marginLeft: 35,
        marginRight: 10,
        resize: "none",
        overflow: "hidden",
    },
    usersName: {
        overflowWrap: "break-word",
        overflow: "visible",
        wordWrap: "break-word",
        flexWrap: "wrap",
    },
    bottomRow: {
        margin: theme.spacing(1)
    },
    message: {
        width: "100%",
        marginTop: 3,
        '&.error': {
            color: theme.palette.secondary.main,
            animation: "fadeInLeft",
            animationDuration: "300ms",
        },
        '&.success': {
            color: theme.palette.primary.main,
            animation: "fadeInLeft",
            animationDuration: "300ms",
        }
    },
    nameContainerFlex: {
        display: "flex",
        maxWidth: "100%",
    },
    nameTextField: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
