import React from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import ValidationAccountForm from "../../Forms/ValidationAccount";
import { isUndefined } from "lodash";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import { useStyles } from "./style";

const CreateEditValidationAccount = ({
  onDelete,
  contractHolderID,
  onChange,
  facilityID,
  onGoBackClick
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { validationAccountID: routeID } = useParams();

  contractHolderID = !isUndefined(contractHolderID)
    ? contractHolderID
    : routeID !== "add"
    ? routeID
    : undefined;

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div>
      <Button
        className={clsx("go-back-btn", classes.goBackBtn)}
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={onGoBackClick}
      >
        Go Back
      </Button>
      <ValidationAccountForm
        contractHolderID={contractHolderID}
        onCancel={handleCancel}
        onChange={onChange}
        onDelete={onDelete}
        facilityID={facilityID}
      />
    </div>
  );
};

CreateEditValidationAccount.defaultProps = {
  onDelete: () => {},
  onGoBackClick: () => {}
};

CreateEditValidationAccount.propTypes = {
  onDelete: PropTypes.func,
  onGoBackClick: PropTypes.func
};

export default CreateEditValidationAccount;
