import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from "./styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid, Typography, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import useUserContext from "../../hooks/useUserContext";
import SquareChip from "../SquareChip";
import { setUsername, setFirstname, setLastname } from "../../state/slices/user";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required")
});

export const UserDemographics = ({ userID }) => {        //maybe someday let this have a userID passed in (would need to retrieve)
  const classes = useStyles();
  const currentUser = useSelector(state => state.user);
  const [messageToRender, setMessage] = useState(null);
  const { updateInfo } = useUserContext();
  const dispatch = useDispatch();

    const { handleSubmit, control, formState: { errors, isSubmitting, isDirty, isValid }} = useForm({
      mode: 'all',
      resolver: yupResolver(userSchema),
      defaultValues: {
          firstName: currentUser.Firstname,
          lastName: currentUser.Lastname
      }
  });

  const updateUserInfo = async (values) => {
    setMessage(null);
    try {
      const updateRes = await updateInfo({
        firstName: values.firstName,
        lastName: values.lastName,
      });

      if (updateRes?.status === 200) {
        dispatch(setUsername(`${values.firstName} ${values.lastName}`));
        dispatch(setFirstname(`${values.firstName}`));
        dispatch(setLastname(`${values.lastName}`));
        setMessage({ class: "success", message: "Name Updated" });
      } else {
        setMessage({ class: "error", message: "Could Not Update" });
      }
    } catch (err) {
      setMessage({ class: "error", message: "Could Not Update" });
    }
  };

  return (
    <div className={classes.profile}>
      <Typography className={classes.email}>{currentUser.EmailAddress}</Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit(async (values) => await updateUserInfo(values))}
        noValidate
      >
        <Grid
          container
          className={classes.nameContainerFlex}
        >
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => <TextField
              {...field}
              size="small"
              className={clsx(["firstname", classes.nameTextField])}
              label="First Name"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
              inputProps={{ "aria-label": "First Name" }}
              fullWidth
              required
            />}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => <TextField
              {...field}
              size="small"
              className={clsx(["lastname", classes.nameTextField])}
              label="Last Name"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              inputProps={{ "aria-label": "Last Name" }}
              fullWidth
              required
            />}
          />
        </Grid>
        <Grid container spacing={1} item xs={12} className={classes.bottomRow}>
          <Grid item xs={6} >
            {(messageToRender != null) && (
              <SquareChip
                variant="outlined"
                size="small"
                color={messageToRender.class === "success" ? "primary" : "secondary"}
                text={messageToRender.message}
                className={clsx([classes.message, "name-change", messageToRender.class])}
              >
              </SquareChip>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right", paddingRight: 15 }}>
            <Button
              data-testid="updateButton"
              size="small"
              className={clsx([classes.submitButton, "submit"])}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid || isSubmitting}
              startIcon={<CheckIcon />}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

UserDemographics.defaultProps = { userID: undefined, };
UserDemographics.propTypes = { userID: PropTypes.number, };

export default UserDemographics;
